import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row, CardTitle, Spinner } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { get, put } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import { useHistory, useParams } from "react-router-dom"

const EditUser = props => {
  const history = useHistory()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState({})
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Users", link: "/users" },
    { title: "Edit User", link: "/users/edit/" + id },
  ]

  const handleSubmit = (e, v) => {
    setIsLoading(true)
    let values = v
    values.update_user_id = id
    put("user", values)
      .then(response => {
        if (response.status) {
          history.push("/users")
        } else {
          toast(response.message)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        toast("Please try again later!")
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("Edit Server", breadcrumbItems)
    get(`/user?user_id=${id}`)
      .then(response => {
        if (response.status) {
          setUserData(response.data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again!")
      })
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit User | Delta - Revolution Soft</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">User Details</CardTitle>
              <AvForm onValidSubmit={handleSubmit}>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">First Name</label>
                  <div className="col-md-10">
                    <AvField
                      className="form-control"
                      type="text"
                      name="first_name"
                      placeholder="Enter first name"
                      value={userData.first_name}
                      required
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Last Name</label>
                  <div className="col-md-10">
                    <AvField
                      className="form-control"
                      type="text"
                      name="last_name"
                      placeholder="Enter last name"
                      value={userData.last_name}
                      required
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Email</label>
                  <div className="col-md-10">
                    <AvField
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      value={userData.email}
                      required
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Type</label>
                  <div className="col-md-10">
                    <AvField
                      type="select"
                      name="type"
                      value={userData.type}
                      required
                    >
                      <option>admin</option>
                      <option>developer</option>
                    </AvField>
                  </div>
                </Row>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    ""
                  )}{" "}
                  Submit
                </button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditUser)
