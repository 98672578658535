import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Card, CardBody, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions"
import { put } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"

const UserProfile = props => {
  const history = useHistory()
  const [user, setUser] = useState({})
  useEffect(() => {
    if (localStorage.getItem("user")) {
      const obj = JSON.parse(localStorage.getItem("user"))
      setUser(obj)
    }
  }, [])

  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Profile", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Profile", breadcrumbItems)
  })

  function handleEmailValidSubmit(event, values) {
    put("user/self", values)
      .then(response => {
        if (response.status) {
          history.push("/dashboard")
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later!")
      })
  }

  function handlePasswordValidSubmit(event, values) {
    if (values.password != values.confirm_pass) {
      toast("Passwords dont't match!")
    } else {
      put("change-password", values)
        .then(response => {
          if (response.status) {
            history.push("/logout")
          } else {
            toast(response.message)
          }
        })
        .catch(error => {
          console.log(error)
          toast("Please try again later!")
        })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Profile | Delta - Revolution Soft</title>
      </MetaTags>

      <h4 className="card-title mb-4">Change Email</h4>

      <Card>
        <CardBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleEmailValidSubmit(e, v)
            }}
          >
            <div className="form-group">
              <AvField
                name="email"
                label="Email"
                value={name}
                className="form-control"
                placeholder="Enter Email"
                type="text"
                required
              />
            </div>
            <div className="text-center mt-4">
              <Button type="submit" color="danger">
                Edit Email
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>

      <h4 className="card-title mb-4">Change Password</h4>

      <Card>
        <CardBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handlePasswordValidSubmit(e, v)
            }}
          >
            <div className="form-group">
              <AvField
                name="password"
                label="New Password"
                value={name}
                className="form-control"
                placeholder="Enter new password"
                type="password"
                required
              />
              <AvField
                name="confirm_pass"
                label="Confirm Password"
                value={name}
                className="form-control"
                placeholder="Confirm new password"
                type="password"
                required
              />
            </div>
            <div className="text-center mt-4">
              <Button type="submit" color="danger">
                Edit Password
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {
    setBreadcrumbItems,
  })(UserProfile)
)
