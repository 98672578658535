import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row, CardTitle, Spinner } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { post } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import { useHistory } from "react-router-dom"

const AddProject = props => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Projects", link: "/projects" },
    { title: "Add Project", link: "/projects/add" },
  ]

  const handleSubmit = (e, v) => {
    setIsLoading(true)
    post("project", v)
      .then(response => {
        if (response.status) {
          history.push("/projects")
        } else {
          toast(response.message)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        toast("Please try again later!")
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("Add Project", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Project | Delta - Revolution Soft</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">Project Details</CardTitle>
              <AvForm onValidSubmit={handleSubmit}>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Name</label>
                  <div className="col-md-10">
                    <AvField
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Enter project name"
                      required
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Description</label>
                  <div className="col-md-10">
                    <AvField
                      className="form-control"
                      type="search"
                      name="description"
                      placeholder="Enter project description"
                      required
                    />
                  </div>
                </Row>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    ""
                  )}{" "}
                  Submit
                </button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddProject)
