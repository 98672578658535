import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

const Dashboard = props => {
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard | Delta - Revolution Soft</title>
      </MetaTags>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
