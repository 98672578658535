import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"
// const API_URL = "https://delta-api.revolutionsoftwareservices.com/api/"
// export const PUBLIC_URL = "https://delta-api.revolutionsoftwareservices.com/"
const API_URL = "https://api.almastarts.com/api/"
export const PUBLIC_URL = "https://api.almastarts.com/"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] =
  authHeader().Authorization || ""

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, {
      ...config,
      headers: { Authorization: authHeader().Authorization || "" },
    })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(
      url,
      { ...data },
      {
        ...config,
        headers: { Authorization: authHeader().Authorization || "" },
      }
    )
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(
      url,
      { ...data },
      {
        ...config,
        headers: { Authorization: authHeader().Authorization || "" },
      }
    )
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, {
      ...config,
      headers: { Authorization: authHeader().Authorization || "" },
    })
    .then(response => response.data)
}
