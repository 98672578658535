import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row, CardTitle, Spinner } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { get, post } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import { useHistory } from "react-router-dom"

const AddAccess = props => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])
  const [servers, setServers] = useState([])
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Access List", link: "/access" },
    { title: "Add Access", link: "/access/add" },
  ]

  const getUsers = () => {
    get("user")
      .then(response => {
        if (response.status) {
          setUsers(response.data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later!")
      })
  }

  const getProjects = () => {
    get("project")
      .then(response => {
        if (response.status) {
          setProjects(response.data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later!")
      })
  }

  const getServers = () => {
    get("server")
      .then(response => {
        if (response.status) {
          setServers(response.data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later!")
      })
  }

  const handleSubmit = (e, v) => {
    setIsLoading(true)
    console.log(v)
    post("access", v)
      .then(response => {
        if (response.status) {
          history.push("/access")
        } else {
          toast(response.message)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        toast("Please try again later!")
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("Add Access", breadcrumbItems)
    getUsers()
    getProjects()
    getServers()
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Access | Delta - Revolution Soft</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">Access Details</CardTitle>
              <AvForm onValidSubmit={handleSubmit}>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">User</label>
                  <div className="col-md-10">
                    <AvField type="select" name="user_id" required>
                      {users.map(user => (
                        <option value={user._id}>{user.email}</option>
                      ))}
                    </AvField>
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Project</label>
                  <div className="col-md-10">
                    <AvField type="select" name="project_id" required>
                      {projects.map(project => (
                        <option value={project._id}>{project.name}</option>
                      ))}
                    </AvField>
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Server</label>
                  <div className="col-md-10">
                    <AvField type="select" name="server_id" required>
                      {servers.map(server => (
                        <option value={server._id}>{server.name}</option>
                      ))}
                    </AvField>
                  </div>
                </Row>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    ""
                  )}{" "}
                  Submit
                </button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddAccess)
