import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { get, del } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import GeneralTable from "../../components/Common/GeneralTable"
import { useHistory } from "react-router-dom"

function AccessList(props) {
  const history = useHistory()
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [accessId, setAccessId] = useState("")
  const columns = [
    {
      name: "First Name",
      data_key: "first_name",
    },
    {
      name: "Last Name",
      data_key: "last_name",
    },
    {
      name: "Project",
      data_key: "project_name",
    },
    {
      name: "Server",
      data_key: "server_name",
    },
    {
      name: "Actions",
      render: record => (
        <i
          style={{ color: "red" }}
          className="fas fa-trash-alt ms-4"
          onClick={() => {
            toggleModal(record._id)
          }}
        ></i>
      ),
    },
  ]
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Access List", link: "/access" },
  ]

  const loadAccessData = () => {
    get("access")
      .then(response => {
        if (response.status) {
          let accessList = response.data.map(element => {
            return {
              _id: element._id,
              first_name: element.user_id.first_name,
              last_name: element.user_id.last_name,
              project_name: element.project_id.name,
              server_name: element.server_id.name,
            }
          })
          setTableData(accessList)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later")
      })
  }

  const handleAddAccessClick = () => {
    history.push("/access/add")
  }

  const toggleModal = accessId => {
    setIsModalVisible(!isModalVisible)
    if (accessId) {
      setAccessId(accessId)
    } else {
      setAccessId("")
    }
  }

  const handleAccessDelete = () => {
    del(`access?access_id=${accessId}`)
      .then(response => {
        setIsDeleteButtonLoading(false)
        toggleModal()
        toast(response.message)
        if (response.status) {
          loadAccessData()
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later!")
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("Access List", breadcrumbItems)
    loadAccessData()
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Access List | Delta - Revolution Soft</title>
      </MetaTags>
      <Modal isOpen={isModalVisible} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete Access</ModalHeader>
        <ModalBody>Are you sure ?</ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            disable={isDeleteButtonLoading.toString()}
            onClick={handleAccessDelete}
          >
            {isDeleteButtonLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              ""
            )}{" "}
            Yes
          </button>{" "}
          <button className="btn btn-secondary" onClick={toggleModal}>
            No
          </button>
        </ModalFooter>
      </Modal>
      <button className="btn btn-primary" onClick={handleAddAccessClick}>
        Add Access
      </button>
      <GeneralTable columns={columns} data={tableData} />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AccessList)
