import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { get, post, del, PUBLIC_URL } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import GeneralTable from "../../components/Common/GeneralTable"
import { useHistory } from "react-router-dom"

function Servers(props) {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [serverId, setServerId] = useState("")
  const columns = [
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Domain",
      data_key: "domain",
    },
    {
      name: "Actions",
      render: record => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isLoading}
            onClick={() => {
              handleServerLogin(record._id, record.domain)
            }}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              ""
            )}{" "}
            Login
          </button>
          <i
            style={{ color: "red" }}
            className="fas fa-trash-alt ms-4"
            onClick={() => {
              toggleModal(record._id)
            }}
          ></i>
          <i
            style={{ color: "green" }}
            className="fas fa-edit ms-4"
            onClick={() => {
              history.push(`/servers/edit/${record._id}`)
            }}
          ></i>
        </div>
      ),
    },
  ]
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Servers", link: "/servers" },
  ]

  const loadServerData = () => {
    get("server")
      .then(response => {
        if (response.status) {
          setTableData(response.data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later")
      })
  }

  const handleAddServerClick = () => {
    history.push("/servers/add")
  }

  const handleServerLogin = (serverId, domain) => {
    setIsLoading(true)
    post("server-login", { server_id: serverId })
      .then(response => {
        setIsLoading(false)
        if (response.status) {
          let url = `https://${domain}/smanage.php?hsht=${encodeURIComponent(
            response.token
          )}`
          console.log(url)
          window.open(url, "_blank")
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        toast("Please try again later!")
      })
  }

  const handleConnectionFileDownloadClick = () => {
    window.open(`${PUBLIC_URL}smanage.php`, "_blank")
  }

  const toggleModal = serverId => {
    setIsModalVisible(!isModalVisible)
    if (serverId) {
      setServerId(serverId)
    } else {
      setServerId("")
    }
  }

  const handleServerDelete = () => {
    del(`server?server_id=${serverId}`)
      .then(response => {
        setIsDeleteButtonLoading(false)
        toggleModal()
        toast(response.message)
        if (response.status) {
          loadServerData()
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later!")
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("Servers", breadcrumbItems)
    loadServerData()
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Servers | Delta - Revolution Soft</title>
      </MetaTags>
      <Modal isOpen={isModalVisible} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete Server</ModalHeader>
        <ModalBody>Are you sure ?</ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            disable={isDeleteButtonLoading}
            onClick={handleServerDelete}
          >
            {isDeleteButtonLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              ""
            )}{" "}
            Yes
          </button>{" "}
          <button className="btn btn-secondary" onClick={toggleModal}>
            No
          </button>
        </ModalFooter>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <button className="btn btn-primary" onClick={handleAddServerClick}>
          Add Server
        </button>
        <button
          className="btn btn-primary ms-4"
          onClick={handleConnectionFileDownloadClick}
        >
          Download Connection File
        </button>
      </div>
      <GeneralTable columns={columns} data={tableData} />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Servers)
