import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { logoutUser } from "../../store/actions"
import { post } from "../../helpers/api_helper"
import { useHistory } from "react-router-dom"

const Logout = props => {
  const history = useHistory()

  useEffect(() => {
    post("logout")
    localStorage.clear()
    history.push("/")
  })

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
}

export default withRouter(connect(null, { logoutUser })(Logout))
