import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { Spinner } from "reactstrap"
import { get } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  onlyAdmin,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isValidSession, setIsValidSession] = useState(false)
  const [userType, setUserType] = useState("")

  useEffect(() => {
    let user = localStorage.getItem("user")
    if (user) {
      setUserType(JSON.parse(user).type)
      console.log(JSON.parse(user).type)
    }
    get("session")
      .then(response => {
        setIsValidSession(response.status)
        if (!response.status) {
          toast(response.message)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <Route
          {...rest}
          render={props => {
            if (
              isAuthProtected &&
              (!localStorage.getItem("token") || !isValidSession)
            ) {
              return (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )
            } else {
              if (onlyAdmin && userType != "admin") {
                return (
                  <Redirect
                    to={{ pathname: "/", state: { from: props.location } }}
                  />
                )
              } else {
                return (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                )
              }
            }
          }}
        />
      )}
    </>
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
