import React from "react"
import { Redirect } from "react-router-dom"
import Dashboard from "../pages/Dashboard/index"
import UserProfile from "../pages/Authentication/user-profile"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Servers from "../pages/Servers"
import AddServer from "pages/Servers/AddServer"
import EditServer from "pages/Servers/EditServer"
import Projects from "pages/Projects"
import AddProject from "pages/Projects/AddProject"
import EditProject from "pages/Projects/EditProject"
import Users from "pages/Users"
import AddUser from "pages/Users/AddUser"
import EditUser from "pages/Users/EditUser"
import AccessList from "pages/AccessManagement"
import AddAccess from "pages/AccessManagement/AddAccess"
import MyServers from "pages/AccessManagement/MyServers"
import ActivityLogs from "pages/ActivityLogs"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: UserProfile },
  { path: "/servers", component: Servers, admin: true },
  { path: "/servers/add", component: AddServer, admin: true },
  { path: "/servers/edit/:id", component: EditServer, admin: true },
  { path: "/projects", component: Projects, admin: true },
  { path: "/projects/add", component: AddProject, admin: true },
  { path: "/projects/edit/:id", component: EditProject, admin: true },
  { path: "/users", component: Users, admin: true },
  { path: "/users/add", component: AddUser, admin: true },
  { path: "/users/edit/:id", component: EditUser, admin: true },
  { path: "/access", component: AccessList, admin: true },
  { path: "/access/add", component: AddAccess, admin: true },
  { path: "/access/my", component: MyServers },
  { path: "/activity-logs", component: ActivityLogs, admin: true },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
