import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { get } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import GeneralTable from "../../components/Common/GeneralTable"

function ActivityLogs(props) {
  const [tableData, setTableData] = useState([])
  const columns = [
    {
      name: "Title",
      data_key: "title",
    },
    {
      name: "User",
      data_key: "user",
    },
    {
      name: "Email",
      data_key: "email",
    },
    {
      name: "Date",
      data_key: "createdAt",
    },
  ]
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Activity Logs", link: "/activity-logs" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Activity Logs", breadcrumbItems)
    get("activity-logs")
      .then(response => {
        if (response.status) {
          let data = response.data.map(element => {
            return {
              title: element.title,
              user: `${element.user_id.first_name} ${element.user_id.last_name}`,
              email: element.user_id.email,
              createdAt: element.createdAt,
            }
          })
          setTableData(data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later")
      })
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Activity Logs | Delta - Revolution Soft</title>
      </MetaTags>
      <GeneralTable columns={columns} data={tableData} />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ActivityLogs)
