import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { get, post, del, PUBLIC_URL } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import GeneralTable from "../../components/Common/GeneralTable"
import { useHistory } from "react-router-dom"

function MyServers(props) {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const columns = [
    {
      name: "Server",
      data_key: "server_name",
    },
    {
      name: "Project",
      data_key: "project_name",
    },
    {
      name: "Actions",
      render: record => (
        <button
          className="btn btn-primary"
          type="submit"
          disabled={isLoading}
          onClick={() => {
            handleServerLogin(record.server_id, record.domain)
          }}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            ""
          )}{" "}
          Login
        </button>
      ),
    },
  ]
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "My Servers", link: "/access/my" },
  ]

  const loadServerData = () => {
    get("/access/my")
      .then(response => {
        if (response.status) {
          let data = response.data.map(element => {
            return {
              server_name: element.server_id.name,
              project_name: element.project_id.name,
              server_id: element.server_id._id,
              domain: element.server_id.domain,
            }
          })
          setTableData(data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later")
      })
  }

  const handleServerLogin = (serverId, domain) => {
    setIsLoading(true)
    post("server-login", { server_id: serverId })
      .then(response => {
        setIsLoading(false)
        if (response.status) {
          let url = `https://${domain}/smanage.php?hsht=${encodeURIComponent(
            response.token
          )}`
          console.log(url)
          window.open(url, "_blank")
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        toast("Please try again later!")
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("My Servers", breadcrumbItems)
    loadServerData()
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Servers | Delta - Revolution Soft</title>
      </MetaTags>
      <GeneralTable columns={columns} data={tableData} />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyServers)
