import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { get, del } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import GeneralTable from "../../components/Common/GeneralTable"
import { useHistory } from "react-router-dom"

function Projects(props) {
  const history = useHistory()
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [projectId, setProjectId] = useState("")
  const columns = [
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Description",
      data_key: "description",
    },
    {
      name: "Actions",
      render: record => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <i
            style={{ color: "red" }}
            className="fas fa-trash-alt ms-4"
            onClick={() => {
              toggleModal(record._id)
            }}
          ></i>
          <i
            style={{ color: "green" }}
            className="fas fa-edit ms-4"
            onClick={() => {
              history.push(`/projects/edit/${record._id}`)
            }}
          ></i>
        </div>
      ),
    },
  ]
  const breadcrumbItems = [
    { title: "Delta - Revolution Soft", link: "#" },
    { title: "Projects", link: "/projects" },
  ]

  const loadProjectData = () => {
    get("project")
      .then(response => {
        if (response.status) {
          setTableData(response.data)
        } else {
          toast(response.message)
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later")
      })
  }

  const handleAddProjectClick = () => {
    history.push("/projects/add")
  }

  const toggleModal = projectId => {
    setIsModalVisible(!isModalVisible)
    if (projectId) {
      setProjectId(projectId)
    } else {
      setProjectId("")
    }
  }

  const handleProjectDelete = () => {
    del(`project?project_id=${projectId}`)
      .then(response => {
        setIsDeleteButtonLoading(false)
        toggleModal()
        toast(response.message)
        if (response.status) {
          loadProjectData()
        }
      })
      .catch(error => {
        console.log(error)
        toast("Please try again later!")
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("Projects", breadcrumbItems)
    loadProjectData()
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Projects | Delta - Revolution Soft</title>
      </MetaTags>
      <Modal isOpen={isModalVisible} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete Project</ModalHeader>
        <ModalBody>Are you sure ?</ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            disable={isDeleteButtonLoading.toString()}
            onClick={handleProjectDelete}
          >
            {isDeleteButtonLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              ""
            )}{" "}
            Yes
          </button>{" "}
          <button className="btn btn-secondary" onClick={toggleModal}>
            No
          </button>
        </ModalFooter>
      </Modal>
      <button className="btn btn-primary" onClick={handleAddProjectClick}>
        Add Project
      </button>
      <GeneralTable columns={columns} data={tableData} />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Projects)
